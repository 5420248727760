import dayjs from 'dayjs';

export const TENANT_ID = '482EA09F-FC81-447B-8442-2AAAAA954D04';
export const VISIT_ID = '00000000-0000-0000-0000-000000000000';
export const Document_Type_Id = 'B27D8A5E-DE8C-4189-96E6-E486C6B2E082';
export const TENANT_DOCUMENT_ID = '00000000-0000-0000-0000-000000000000';
export const REJECTED_STATUS_ID = '61036779-17de-4439-b386-ad358cb55d43';
export const DEFAULT_LOCATION_ID = '00000000-0000-0000-0000-000000000000';
export const DISCARD_STATUS_ID = '272b06d6-eec3-462d-ba14-431ca01f2038';
export const VISIT_LATERALITY_LATERAL = 'Lateral';
export const VISIT_STATUS_COMPLETE = 'Completed';
export const VISIT_STATUS_REJECTED = 'Patient Refused';
export const VISIT_STATUS_DISCARD = 'Discard';
export const VISIT_STATUS_TRANSMIT_DOCUMENTS = 'Transmit Documents';
export const VISIT_STATUS_TRANSMIT_DOCUMENT_ERROR = 'Transmit Document Error';
export const VISIT_STATUS_TRANSMIT_DOCUMENTS_ERROR = 'Transmit Documents Error';
export const VISIT_STATUS_TRANSMIT_CHARGES = 'Transmit Charges';
export const VISIT_STATUS_TRANSMIT_CHARGES_ERROR = 'Transmit Charge Error';
export const VISIT_STATUS_FINALIZE_VISIT = 'Finalize Visit';
export const VISIT_STATUS_FINALIZE_DOCUMENTS = 'Finalize Documents';
export const UNFINISHED_VISITS_ID = '4a7769b9-f617-4992-a781-f6accb3e7d71';
export const NOT_TO_SEND_BILLING_ID = '741b056f-a0f6-4b48-8bcb-db01224121f4';
export const WRONG_PATIENT = 'Wrong Patient';
export const OTHER = 'Other';
export const LOADER_API_RESPONSE_CODE = 3;
export const ENTER = 'Enter';
export const CLICK = 'click';
export const SELECTED = 'selected';
export const ITEMS_IN_DEFICIT = 'Items in Deficit';
export const ITEMS_IN_DEFICIT_ID = '451bdb82-d242-4a54-a6f0-3c22f13d0577';
export const NUMBER_SINGLE_CONSTANT = 1;
export const DEAD_STOCK_COUNT = 'e355b424-d9d1-4013-9714-86fa9d7301a1';
export const FREQUENTLY_DISPENSE_ITEM = '3f39173b-3014-42ba-9280-3217dc9de01c';
export const PRIMARY = 'Primary';
export const SECONDARY = 'Secondary';
export const TERTIARY = 'Tertiary';

export const EMPTY_DATE = '0001-01-01T00:00:00';
export const EMPTY_GUID = '00000000-0000-0000-0000-000000000000';
export const MASTER_HCPCS = 'masterHcpcs';
export const TENANT_HCPCS = 'tenantHcpcs';
export const CHARGE_MASTER = 'chargeMaster';
export const MEDICARE_FEE_SCHEDULE = 'medicareFeeSchdule';
export const OTHER_FEE_SCHEDULE = 'otherFeeSchedules';

export const dataEmpty = '--';
export const baseURL = process.env.REACT_APP_API_BASE;
export const allowedFileTypes = 'text/csv';

export const DASHBOARD_MENU = 'Dashboard';
export const PATIENTS_MENU = 'Patients';
export const VISITS_MENU = 'Visits';
export const STOCK_MENU = 'Stock';
export const PURCHASE_MENU = 'Purchase';
export const CATALOG_MENU = 'Catalog';
export const REPORTS_MENU = 'Reports';
export const SETTINGS_MENU = 'Settings';
export const HELP_MENU = 'Help';

export const AXIOS_BASE_CONFIG = {
  baseURL,
  headers: {
    'X-Requested-With': 'XMLHttpRequest',
    'Access-Control-Expose-Headers': 'Content-Disposition'
  }
};

export const IPADDRESS_FETCH = 'https://api.ipify.org/?format=text';

export const ENDPOINTS = {
  //Common
  RENAME_DOCUMENT: 'patientservice/api/DocumentUpload/RenameDocument',
  TENANT_INFO_FETCH: 'userservice/api/admin/TenantDetails/GetTenantInformation',
  TIMEZONE_FETCH: 'userservice/api/admin/TenantDetails/GetTimeZoneList',
  FETCH_STATE: 'userservice/api/StateProvinces/stateprovincesbystatename',
  UPDATE_TENANT_INFO: 'userservice/api/admin/TenantDetails/UpdateTenantInfo',
  DELETE_POC: 'userservice/api/admin/TenantDetails/DeletePointofContactById',
  TENANT_LOGO_UPLOAD: 'userservice/api/admin/TenantDetails/UploadTenantLogo',
  SEARCH_CATALOG_HCPCS: 'userservice/api/Hcpcs/GetSearchHcpcs',
  VISIT_MASTER_FETCH_BILLING_TYPES: 'inventoryservice/api/inventory/GetBillingTypes',
  TERMCONDITION: 'UserService/api/TermsAndCondition/gettermsandconditions',
  POSTTERMCONDITION: 'UserService/api/TermsAndCondition/createorupdatetermsandconditions',
  VALIDATE_USER: 'userservice/api/User/ValidateUser/',
  USER_TENANT: 'UserService/api/Locations/UserTenantsInformation',
  UPDATE_USER_LOGOUT_TIME: 'UserService/api/User/UpdateUserLastLoggedOutTime',
  USER_TENANT_UPDATE: 'UserService/api/Locations/LastLoggedinfoDetailsWorkflow',
  DASHBOARD_TILES: 'patientservice/api/Dashboard/DashboardTiles',
  DASHBOARD_PURCHASE_TILES: 'inventoryservice/api/DashboardInventory/DashboardPurchaseTiles',
  DASHBOARD_CATALOG_TILES: 'inventoryservice/api/DashboardInventory/DashboardCatelogTiles',
  DASHBOARD_STOCK_TILES: 'inventoryservice/api/DashboardInventory/DashboardStockTiles',
  DASHBOARD_VISIT_TILES: 'patientservice/api/Dashboard/DashboardTiles/GetVisitTiles',
  DASHBOARD_ITEMS_TO_ORDER_TILES:
    'inventoryservice/api/DashboardInventory/DashboardShoppingItemsTiles',
  DASHBOARD_COMPLETED_VISIT_TILES:
    'patientservice/api/Dashboard/DashboardTiles/GetCompletedVisitTiles',
  DASHBOARD_PATIENT_TILES: 'patientservice/api/Dashboard/DashboardTiles/GetPatientTiles',
  USER_TENANT_LOCATION: 'userservice/api/Locations/LocationDropDown',
  VISIT_MASTER_PROVIDER_DETAILS: 'userservice/api/ProviderType/ProvidersTypes',
  VISIT_MASTER_VISIT_STATUS: 'userservice/api/VisitStatus/visitStatus',
  VISIT_MASTER_AUTH_STATUS: 'UserService/api/AuthStatus/authStatus',
  GET_MFA_DETAILS: 'UserService/api/admin/AzureAd/GetMFAStatus',
  UPDATE_MFA_SETTING: 'UserService/api/admin/AzureAction/ReplaceMFA'
};

export const LOGIN_REDIRECT_SCOPE = [
  'https://devempower30.onmicrosoft.com/userapi/userapi.read',
  'https://devempower30.onmicrosoft.com/userapi/userapi.write'
];

export const LOGIN_REDIRECT_SCOPE_PROD = [
  'https://empowerxprod.onmicrosoft.com/api/read',
  'https://empowerxprod.onmicrosoft.com/api/write'
];

export const VISIT_STEPS = [
  'Select Patient',
  'Manage Items',
  'Visit Details',
  'Estimated Responsibility',
  'Finalize Visit'
];

export const NAMES = [
  'Oliver Hansen',
  'Van Henry',
  'April Tucker',
  'Ralph Hubbard',
  'Omar Alexander',
  'Carlos Abbott',
  'Miriam Wagner',
  'Bradley Wilkerson',
  'Virginia Andrews',
  'Kelly Snyder'
];

export const MANAGE_ITEMS_ERROR = {
  invalidDiagnosisErrorMsg: 'Warning: Invalid diagnosis code',
  missingDiagnosisErrorMsg: 'Diagnosis code missing',
  lotMissingErrorMsg: 'Lot/Serial number missing',
  specificSideErrorMsg: 'Please select specify side',
  customfitErrorMsg: 'Custom fit description',
  priorAuthErrorMsg: 'Error: Unable to add prior authorization code',
  priorAiuthrequired: 'Auth is Pending'
};

export const PAGE_SIZE = 10;
export const DOCUMENT_TEXT_LIMIT = 255;
export const PAGE_SIZE_OPTIONS = [10, 25, 50];
export const FORMATTED_DATE = dayjs().format('MM/DD/YYYY');

export const GREEN_COLOR = '#299824';
export const RED_COLOR = '#D63649';

export const PATIENT_FILE_SIZE = 100 * 1024 * 1024; // 100 MB
export const MAX_PATIENT_FILE_SIZE = 10 * 1024 * 1024; // 10 MB
export const PATIENT_MAX_FILES = 3;
export const PO_STATUS_FLAG_APPROVE = 3;
export const PO_STATUS_APPROVE_TANSMIT = 4;

export const PDF_VIEWER_CONFIG = {
  enableAnnotationAPIs: true,
  defaultViewMode: 'FIT_WIDTH',
  showLeftHandPanel: false,
  showPageControls: false,
  showDownloadPDF: false,
  showPrintPDF: false
  // showAnnotationTools: false,
};

export const DOCUMENT_TYPE_ID = 'B27D8A5E-DE8C-4189-96E6-E486C6B2E082';
export const PATIENT_DOCUMENT_TYPE_ID = '2EB47BD6-5423-4C92-9111-CD05217D38CE';
export const SHOPPING_CART_STATUS_ID = '5a710800-837e-42a3-90f3-98892c58ddcb';
export const DRAFT_PO_STATUS_ID = 'ec29b07c-0352-4722-a825-1f426331d67f';
export const REJECT_PO_STATUS_ID = 'a39e0430-0cfa-424d-b698-99452b0a5051';
export const APPROVAL_PO_STATUS_ID = '38b9150c-36e2-453b-b626-87d64631d452';
export const ALL_STATUS = 'All';
export const SCHEDULED_STATUS = 'Scheduled Reports';
export const REPORT_RESULT_STATUS = 'Report Results';

export const COMPLETED_PO = 'Completed POs';
export const PO_NEEDS_APPROVAL = 'POs Need Approval';
export const DRAFT_PO = 'Draft POs';
export const RECEIVE_PO = 'POs Sent to Vendor';
export const SHOPPING_CART_ITEM = 'Shopping Cart Items';
export const REJECTED_PO = 'Rejected POs';
export const PURCHASE_MANAGEMENT = 'Purchase Management';
export const CATALOG_BUNDLE_STATUS_ID = 'dab4efde-0c6e-4dfd-90f1-2ddce2bd1f2d';
export const CATALOG_MISSING_COG_STATUS_ID = '2b9a13ca-1199-41ed-a1d6-d4f031d428e6';
export const CATALOG_DISCONTINUE_PRODUCTS_STATUS_ID = '75c44417-7f52-416a-aad3-eb911f85b19a';

export const VENDOR_PO_STATUS_ID = 'ee458ce0-7f63-4aed-b9bb-6a40ee138ae7';

export const MUI_PRO_LICENSE =
  '0072ab034afcabef5226a889b368aeddTz04MTIwOSxFPTE3MzUzMTYwOTgwMDAsUz1wcm8sTE09c3Vic2NyaXB0aW9uLEtWPTI=';

export const AG_GRID_LICENCE =
  'Using_this_{AG_Charts_and_AG_Grid}_Enterprise_key_{AG-052359}_in_excess_of_the_licence_granted_is_not_permitted___Please_report_misuse_to_legal@ag-grid.com___For_help_with_changing_this_key_please_contact_info@ag-grid.com___{Empower_Reimbursement_Services}_is_granted_a_{Single_Application}_Developer_License_for_the_application_{Empower}_only_for_{1}_Front-End_JavaScript_developer___All_Front-End_JavaScript_developers_working_on_{Empower}_need_to_be_licensed___{Empower}_has_been_granted_a_Deployment_License_Add-on_for_{1}_Production_Environment___This_key_works_with_{AG_Charts_and_AG_Grid}_Enterprise_versions_released_before_{18_December_2024}____[v3]_[0102]_MTczNDQ4MDAwMDAwMA==e9c8a982b229ac4589e358d08d6ae9b8';

export const DUMMY_VISIT_ID = '00000000-0000-0000-0000-000000000000';

export const CATLOG_TEXT_1 = 'Item Deficits';
export const CATLOG_TEXT_2 = 'Incomplete Catalog';
export const CATLOG_TEXT_3 = 'Stock Count';

export const DEFAULT_VISIT_DURATION = '20';

export const VISIT_DURATION = [
  { duration: '10', unit: 'minutes' },
  { duration: '20', unit: 'minutes' },
  { duration: '30', unit: 'minutes' },
  { duration: '45', unit: 'minutes' },
  { duration: '60', unit: 'minutes' },
  { duration: '90', unit: 'minutes' }
];

export const REJECT_REASONS = [
  'PO created in Error',
  'Did not meet Shipping Threshold',
  'Quantity not necessary at this time'
];

export const VISIT_TXT = 'Visits';
export const PATIENT_TXT = 'Patients';
export const PURCHASE_TXT = 'Purchase';
export const REPORT_TXT = 'Reports';
export const SETTING_TXT = 'Settings';
export const CATLOG_TXT = 'Catalog';
export const VISIT_HD_TXT = 'Visit Management';
export const PATIENT_HD_TXT = 'Patient Management';
export const PURCHASE_HD_TXT = 'Purchase Management';
export const REPORT_HD_TXT = 'Report Management';
export const SETTINGS_HD_TXT = 'Setting Management';

export const STOCK_TXT = 'Stock';
export const CATALOG_TXT = 'Catalog';
export const STOCK_HD_TXT = 'Stock Management';
export const CATALOG_HD_TXT = 'Catalog Management';

export const LOCALE_DATE_OPTIONS = {
  year: 'numeric',
  month: '2-digit',
  day: '2-digit'
};

export const UPS_TRACKING_LINK = 'https://wwwapps.ups.com/tracking/tracking.cgi?tracknum=';
export const USPS_TRACKING_LINK = 'https://tools.usps.com/go/TrackConfirmAction.action?tLabels=';
export const FEDX_TRACKING_LINK =
  'https://www.fedex.com/apps/fedextrack/?action=track&trackingnumber=';

export const MODMED = 'MODMED';

export const HTMLINPUTTYPE = {
  RADIO: 'radio',
  TOGGLE: 'toggle',
  DATERANGE: 'daterange',
  DROPDOWN: 'dropdown',
  DATE: 'date'
};
export const REPORT_STATUS = {
  COMPLETED: 'Completed'
};
export const SCHEDULED_TYPE = [
  {
    label: 'Email',
    id: 'has_email_sent'
  },
  {
    label: 'SFTP Upload',
    id: 'uploaded_to_sftp'
  }
];

const PROTOCOL = '^(?!ftp:\\/\\/)(?:https?:\\/\\/)?';
const WWW = '(?:www\\.)?';
const DOMAIN_NAME = '[a-zA-Z0-9][a-zA-Z0-9-]{0,61}[a-zA-Z0-9]';
const TLD = '\\.[a-zA-Z]{2,6}';
const PATH = '(?:\\/[-a-zA-Z0-9@:%_+.~#?&/=]*)?$';

export const EMAIL_REGX = /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/;
export const URL_REGEX = new RegExp(PROTOCOL + WWW + DOMAIN_NAME + TLD + PATH);
export const URL_REGX = /^((https?:\/\/)|(www\.))([a-zA-Z\d-]+\.)+[a-zA-Z]{2,}(:\d{1,5})?(\/\S*)?$/;
export const VALUE_SETTER_REGEX = /^\d+$/;
export const DECIMAL_VALUE_SETTER = /^-?\d*(\.\d+)?$|null/;
export const CATALOG_URL_REGEX =
  /^((http(s+)):\/\/)?([wW]{3}\.)?[a-zA-Z0-9\-.]+\.[a-zA-Z]{2,}(\.[a-zA-Z]{2,})+$/g;
export const UPLOAD_SFTP = 'uploaded_to_sftp';
export const HAS_EMAIL_SENT = 'has_email_sent';
export const ONLY_NUM = /^\d*$/;
export const THRESHOLD_VALID_REGEX = /^\d{1,5}(\.\d{0,2})?$/;
export const ZIPCODE_VALID_REGEX = /^\d{5}$/;
export const ATLEAST_ONE_EMAIL = 'Atleast one email is required';
export const CONTACT_SUPPORT_EMAIL = 'empowerx@empower123.com';
export const DIAGNOSIS_CODE_REQUIRED = 'Diagnosis Code is Required';

export const cronDropDownConfigs = {
  minutes: {
    filterOption: ({ value }) => {
      const allowedValues = ['0', '15', '30', '45'];
      return allowedValues.includes(value);
    }
  }
};
