import { createAsyncThunk } from '@reduxjs/toolkit';
import { ENDPOINTS as REPORT_ENDPOINTS } from '../../pages/Reports/utils';
import { ENDPOINTS } from '../../pages/Settings/utils';
import { ENDPOINTS as COMMON_ENDPOINTS } from '../../utils/shared/constants';
import apiClient from '../axios';

export const fetchSubscriptionReports = createAsyncThunk(
  'settings/fetchSubscriptionReports',
  async () => {
    const { data } = await apiClient.get(`${REPORT_ENDPOINTS.GET_REPORT_RESULT}?Is_Scheduled=true`);
    return data;
  }
);

export const GetBillingChargeMaster = createAsyncThunk(
  'settings/GetBillingChargeMaster',
  async () => {
    const { data } = await apiClient.get(ENDPOINTS.GET_BILLING_CHARGE_MASTER);
    return { data };
  }
);

export const AddRecordInChargeMaster = createAsyncThunk(
  'settings/AddRecordInChargeMaster',
  async (payload, { rejectWithValue }) => {
    try {
      const { data } = await apiClient.post(ENDPOINTS.ADD_RECORD_IN_CHARGE_MASTER, payload);
      return { data };
    } catch (error) {
      return rejectWithValue(error.response ? error.response?.data : error.message);
    }
  }
);

export const UpdateChargeMaster = createAsyncThunk(
  'settings/UpdateChargeMaster',
  async (payload, { rejectWithValue }) => {
    try {
      const { data } = await apiClient.put(ENDPOINTS.UPDATE_CHARGE_MASTER, payload);
      return { data };
    } catch (error) {
      return rejectWithValue(error.response ? error.response?.data : error.message);
    }
  }
);

export const AddRoleWithPermissions = createAsyncThunk('settings/AddNewRole', async (payload) => {
  const { data } = await apiClient.post(ENDPOINTS.ADD_ROLE_WITH_PERMISSION, payload);
  return { data };
});

export const GetAllRolePermissions = createAsyncThunk(
  'settings/GetAllRolePermissions',
  async () => {
    const { data } = await apiClient.get(ENDPOINTS.GET_ALL_ROLE_PERMISSIONS);
    return { data };
  }
);

export const GetAllPermissions = createAsyncThunk('settings/GetAllPermissions', async () => {
  const { data } = await apiClient.get(ENDPOINTS.ROLE_GET_ALL_PERMISSION);
  return { data };
});

export const UpdateRolePermission = createAsyncThunk(
  'settings/UpdateRolePermission',
  async (payload) => {
    const { data } = await apiClient.put(ENDPOINTS.UPDATE_ROLE_PERMISSION, payload);
    return { data };
  }
);

export const deleteRolePermission = createAsyncThunk(
  'settings/UpdateRolePermission',
  async (roleId) => {
    const { data } = await apiClient.delete(`${ENDPOINTS.DELETE_ROLE_PERMISSION}?roleId=${roleId}`);
    return { data };
  }
);

export const deleteChargeMaster = createAsyncThunk(
  'settings/deleteChargeMaster',
  async (id, { rejectWithValue }) => {
    try {
      const { data } = await apiClient.delete(`${ENDPOINTS.DELETE_CHARGE_MASTER}?id=${id}`);
      return { data };
    } catch (error) {
      return rejectWithValue(error.response ? error.response?.data?.errorMessage : error.message);
    }
  }
);

export const GetPayers = createAsyncThunk(
  'settings/GetPayers',
  async ({ status, isGA = false }) => {
    const url = `${ENDPOINTS.GET_PAYERS}?Status=${status}${isGA ? '&Is_global_p=true' : ''}`;
    const { data } = await apiClient.get(url);
    return { data };
  }
);

// Vendor Managament
export const GetVendorList = createAsyncThunk('settings/GetVendorList', async (status) => {
  const url = `${ENDPOINTS.GET_VENDOR_LIST}?status=${status}`;
  const { data } = await apiClient.get(url);
  return { data };
});

export const GetPayerTypes = createAsyncThunk('settings/GetPayerTypes', async () => {
  const { data } = await apiClient.get(`${ENDPOINTS.GET_PAYERS_TYPES}`);
  return { data };
});

export const AddPayersDetails = createAsyncThunk(
  'settings/AddPayersDetails',
  async (payload, { rejectWithValue }) => {
    try {
      const { data } = await apiClient.post(ENDPOINTS.ADD_PAYERS_DETAILS, payload);
      return { data };
    } catch (error) {
      return rejectWithValue(error.response ? error.response?.data : error.message);
    }
  }
);

export const UpdatePayers = createAsyncThunk(
  'settings/UpdatePayers',
  async (requestData, { rejectWithValue }) => {
    const { payload, tenant_Id, Is_Global = false } = requestData;
    const url = Is_Global
      ? `${ENDPOINTS.UPDATE_PAYERS}?tenant_Id=${tenant_Id}&Is_Global=true`
      : `${ENDPOINTS.UPDATE_PAYERS}?tenant_Id=${tenant_Id}`;
    try {
      const { data } = await apiClient.post(url, payload);
      return { data };
    } catch (error) {
      return rejectWithValue(error.response ? error.response?.data : error.message);
    }
  }
);

export const GetPayerById = createAsyncThunk('settings/GetPayerById', async (PayerId) => {
  const { data } = await apiClient.get(`${ENDPOINTS.GET_PAYER_BY_ID}?PayerId=${PayerId}`);
  return { data };
});

export const GetFeeScheduleList = createAsyncThunk(
  'settings/GetFeeScheduleList',
  async ({ isMedicare }) => {
    const { data } = await apiClient.get(
      `${ENDPOINTS.GET_FEE_SCHEDULE_LIST}?isMedicare=${isMedicare}`
    );
    return { data };
  }
);

export const GetLocationsDetailsByFilter = createAsyncThunk(
  'settings/GetLocationsDetailsByFilter',
  async (payload) => {
    const { data } = await apiClient.post(ENDPOINTS.GET_LOCATIONS_DETAILS_BY_FILTER, payload);
    return { data };
  }
);

export const GetLocationDetailsById = createAsyncThunk(
  'settings/GetLocationDetailsById',
  async (id) => {
    const { data } = await apiClient.get(`${ENDPOINTS.GET_LOCATION_DETAILS_BY_ID}?id=${id}`);

    return { data };
  }
);

export const InsertOrUpdateLocationDetails = createAsyncThunk(
  'settings/InsertOrUpdateLocationDetails',
  async (payload, { rejectWithValue }) => {
    try {
      const { data } = await apiClient.post(ENDPOINTS.INSERT_OR_UPDATE_LOCATIONS_DETAILS, payload);
      return { data };
    } catch (error) {
      return rejectWithValue(error.response ? error.response?.data : '');
    }
  }
);

export const UploadLocationLogo = createAsyncThunk(
  'settings/UploadLocationLogo',
  async (payload, { rejectWithValue }) => {
    try {
      const { data } = await apiClient.post(ENDPOINTS.UPLOAD_LOCATION_LOGO, payload);
      return { data };
    } catch (error) {
      return rejectWithValue(error.response ? error.response?.data : error.message);
    }
  }
);

export const GetItemCategories = createAsyncThunk('settings/GetItemCategories', async () => {
  const { data } = await apiClient.get(ENDPOINTS.SETTINGS_MASTER_HCPCS_ITEM_CATEGORY);
  return { data };
});

export const GetSearchHcpcs = async (searchInput, isCustom = false) => {
  const { data } = await apiClient.get(
    `${COMMON_ENDPOINTS.SEARCH_CATALOG_HCPCS}?searchInput=${searchInput}&is_Custom=${isCustom}&Is_Tenant_Hcpcs=${true}`
  );
  return data;
};

export const GetMasterTenantHCPCS = createAsyncThunk(
  'settings/GetMasterTenantHCPCS',
  async (isCustom = false) => {
    const { data } = await apiClient.get(
      `${ENDPOINTS.SETTINGS_MASTER_TENANT_HCPCS}?isCustom=${isCustom}`
    );
    return data;
  }
);

export const AddUpdateRecordHCPCS = createAsyncThunk(
  'settings/AddUpdateRecordHCPCS',
  async ({ payload, isMaster }, { rejectWithValue }) => {
    try {
      const url = `${ENDPOINTS.SETTINGS_ADD_UPDATE_HCPCS}?isMaster=${isMaster}`;
      const response = await apiClient.post(url, payload);
      return response.data;
    } catch (error) {
      return rejectWithValue(error?.response ? error?.response?.data : '');
    }
  }
);

export const GetFeeScheduleDetails = createAsyncThunk(
  'settings/GetFeeScheduleDetails',
  async ({ feeScheduleName, isMedicare }) => {
    const { data } = await apiClient.get(
      `${ENDPOINTS.GET_FEE_SCHEDULE_DETAILS}?feeScheduleName=${feeScheduleName}&isMedicare=${isMedicare}`
    );
    return { data };
  }
);

export const AddTenantFeeSchedules = createAsyncThunk(
  'settings/AddTenantFeeSchedules',
  async (payload, { rejectWithValue }) => {
    try {
      const { data } = await apiClient.post(ENDPOINTS.ADD_TENANT_FEE_SCHEDULES, payload);
      return { data };
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const UpdateTenantFeeSchedules = createAsyncThunk(
  'settings/UpdateTenantFeeSchedules',
  async (payload, { rejectWithValue }) => {
    try {
      const { data } = await apiClient.put(ENDPOINTS.UPDATE_TENANT_FEE_SCHEDULES, payload);
      return { data };
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const fetchUserList = createAsyncThunk(
  'settings/fetchUserList',
  async ({ status = 'All' }, { rejectWithValue }) => {
    try {
      const { data } = await apiClient.get(`${ENDPOINTS.GET_ALL_USERS_DATA}?UserStatus=${status}`);
      return data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const fetchRoleList = createAsyncThunk('settings/fetchRoleList', async () => {
  const { data } = await apiClient.get(ENDPOINTS.GET_ALL_ROLE);
  return data?.map((d) => {
    d.role_Id = d.roleId;
    return d;
  });
});

export const fetchLocationList = createAsyncThunk('settings/fetchLocationList', async () => {
  const { data } = await apiClient.get(ENDPOINTS.GET_ALL_LOCATION);
  return data;
});

export const addUpdateUser = createAsyncThunk('settings/addUpdateUser', async (payload) => {
  try {
    const { data } = await apiClient.post(ENDPOINTS.ADD_UPDATE_USER, payload);
    return data;
  } catch (error) {
    return error?.response ? error?.response?.data : error?.message;
  }
});

export const getUserDetailsByEmail = createAsyncThunk(
  'settings/getUserDetailsByEmail',
  async (email) => {
    const { data } = await apiClient.get(`${ENDPOINTS.GET_USER_DETAILS_BY_EMAIL}?email=${email}`);
    return data;
  }
);
export const uploadFitterSignature = createAsyncThunk(
  'patient/uploadFitterSignature',
  async (payload) => {
    const { data } = await apiClient.post(ENDPOINTS.UPLOAD_FITTER_SIGNATURE, payload);
    return data;
  }
);

export const fetchUserDataByID = createAsyncThunk('settings/fetchUserDataByID', async (id) => {
  const { data } = await apiClient.get(`${ENDPOINTS.GET_USERS_DATA_ID}${id}`);
  return data;
});

export const GetVisitDocument = createAsyncThunk(
  'settings/GetVisitDocument',
  async ({ status, isEmr = false }) => {
    const { data } = await apiClient.get(
      `${ENDPOINTS.GET_VISIT_DOCUMENTS}?status=${status}&isEmr=${isEmr}`
    );
    return { data };
  }
);

export const GetVisitDocumentTypes = createAsyncThunk(
  'settings/GetVisitDocumentsTypes',
  async () => {
    const { data } = await apiClient.get(ENDPOINTS.GET_VISIT_DOCUMENTS_TYPE);
    return { data };
  }
);

export const GetVisitLocation = createAsyncThunk('settings/GetVisitLocation', async () => {
  const { data } = await apiClient.get(`${ENDPOINTS.VISIT_LOCATION}`);
  return { data };
});

export const GetProviderDetails = createAsyncThunk(
  'settings/GetProviderDetails',
  async (payload) => {
    const response = await apiClient.post(`${ENDPOINTS.GET_PROVIDER_DETAILS}`, payload);
    const data = response?.data?.data;
    const modifiedData = data.map((item) => ({
      ...item,
      fullNameWithCredentials: `${item.first_name} ${item.last_name} ${
        item.credentials || ''
      }`.trim()
    }));
    return { data: modifiedData };
  }
);

export const GetSpeciality = createAsyncThunk('settings/GetSpeciality', async () => {
  const { data } = await apiClient.get(`${ENDPOINTS.GET_SPECIALITY}`);
  return { data };
});

export const GetProviderDetailsByID = createAsyncThunk(
  'settings/GetProviderDetailsByID',
  async (id, { rejectWithValue }) => {
    try {
      const { data } = await apiClient.get(`${ENDPOINTS.GET_PROVIDER_DETAILS_BY_ID}?id=${id}`);
      return data;
    } catch (error) {
      return rejectWithValue(error.response ? error.response?.data : error.errorMessage);
    }
  }
);

export const AddProviderDetails = createAsyncThunk(
  'settings/AddProviderDetails',
  async (payload, { rejectWithValue }) => {
    try {
      const { data } = await apiClient.post(ENDPOINTS.ADD_PROVIDER_DATA, payload);
      return data;
    } catch (error) {
      return rejectWithValue(error.response ? error.response?.data : error.errorMessage);
    }
  }
);

export const UploadProviderSignature = createAsyncThunk(
  'settings/UploadProviderSignature',
  async (payload) => {
    const { data } = await apiClient.post(ENDPOINTS.UPLOAD_PROVIDER, payload);
    return { data };
  }
);

export const GetThirdPartyIntegration = createAsyncThunk(
  'settings/getThirdPartyIntegration',
  async () => {
    const { data } = await apiClient.get(`${ENDPOINTS.GET_THIRD_PARTY_INTEGRATION}`);
    return data;
  }
);

export const GetVisitDocumentsPayerType = createAsyncThunk(
  'settings/GetVisitDocumentsPayerType',
  async () => {
    const { data } = await apiClient.get(`${ENDPOINTS.GET_VISIT_DOCUMENTS_PAYER_TYPE}`);
    return { data };
  }
);

export const UploadMasterDocuments = createAsyncThunk(
  'settings/UploadMasterDocuments',
  async (payload, { rejectWithValue }) => {
    try {
      const { data } = await apiClient.post(ENDPOINTS.UPLOAD_MASTER_DOCUMENT, payload);
      return { data };
    } catch (error) {
      return rejectWithValue(error?.response ? error?.response?.data?.errorMessage : '');
    }
  }
);

export const uploadVisitDocument = createAsyncThunk(
  'patient/uploadVisitDocument',
  async (payload) => {
    const { data } = await apiClient.post(ENDPOINTS.UPLOAD_VISIT_DOCUMENT, payload);
    return data;
  }
);

export const GetEMRList = createAsyncThunk('settings/GetEMRList', async () => {
  const { data } = await apiClient.get(`${ENDPOINTS.GET_EMR_LIST}`);
  return data;
});

export const GetInBound = createAsyncThunk('settings/GetInBound', async () => {
  const { data } = await apiClient.get(`${ENDPOINTS.GET_IN_OUT_BOUND_LIST}?Type=Inbound`);
  return data;
});

export const GetOutBound = createAsyncThunk('settings/GetOutBound', async () => {
  const { data } = await apiClient.get(`${ENDPOINTS.GET_IN_OUT_BOUND_LIST}?Type=Outbound`);
  return data;
});

export const UpdateThirdPartyIntegration = createAsyncThunk(
  'settings/UpdateThirdPartyIntegration',
  async (payload, { rejectWithValue }) => {
    try {
      const { data } = await apiClient.put(ENDPOINTS.UPDATE_THIRD_PARTY_INTEGRATION, payload);
      return { data };
    } catch (error) {
      return rejectWithValue(error.response ? error.response?.data : error.message);
    }
  }
);

export const GetVisitDocumentByID = createAsyncThunk(
  'settings/GetVisitDocumentByID',
  async ({ id, tenantId }) => {
    const { data } = await apiClient.get(
      `${ENDPOINTS.GET_VISIT_DOCUMENT_BY_ID}?Id=${id}&TenantId=${tenantId}`
    );
    return { data };
  }
);

export const fetchVisitDocumentsBillingTypes = createAsyncThunk(
  'visitMaster/fetchVisitDocumentsBillingTypes',
  async () => {
    const { data } = await apiClient.get(COMMON_ENDPOINTS.VISIT_MASTER_FETCH_BILLING_TYPES);
    return data;
  }
);

export const UpdateActivateInactivateUser = createAsyncThunk(
  'settings/UpdateActivateInactivateUser',
  async (payload) => {
    const { data } = await apiClient.put(ENDPOINTS.ACTIVATE_INACTIVATE_USER, payload);
    return { data };
  }
);

export const userResetPassword = createAsyncThunk(
  'settings/userResetPassword',
  async (apiUserTenantData) => {
    const { data } = await apiClient.post(ENDPOINTS.USER_RESET_PASSWORD, apiUserTenantData);
    return data;
  }
);

export const userResetMFA = createAsyncThunk('settings/userResetMFA', async (apiUserTenantData) => {
  const { data } = await apiClient.post(ENDPOINTS.BULK_RESET_MFA, apiUserTenantData);
  return data;
});

export const GetEligibilityPayers = createAsyncThunk('settings/GetEligibilityPayers', async () => {
  const { data } = await apiClient.get(ENDPOINTS.GET_ELIGIBILITY_PAYERS);
  return data;
});

export const InviteUser = createAsyncThunk('settings/InviteUser', async (payload) => {
  try {
    const { data } = await apiClient.post(ENDPOINTS.INVITE_USER, payload);
    return data;
  } catch (error) {
    return error?.response ? error?.response?.data : error?.message;
  }
});

export const GetDocumentData = createAsyncThunk(
  'settings/GetDocumentData',
  async ({ id, module = '', tenantId }, { rejectWithValue }) => {
    try {
      const response = await apiClient.get(
        `${ENDPOINTS.GET_DOCUMENT_DATA}?id=${id}&module=${module}&tenant_id=${tenantId}`,
        {
          responseType: 'arraybuffer'
        }
      );
      return response;
    } catch (error) {
      return rejectWithValue(error?.message);
    }
  }
);

export const GetLocationVendorList = createAsyncThunk(
  'settings/GetLocationVendorList',
  async ({ locationId }) => {
    const { data } = await apiClient.get(
      `${ENDPOINTS.GET_LOCATION_VENDOR_LIST}?locationId=${locationId}`
    );
    return { data };
  }
);
